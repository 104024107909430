import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import './ConfirmationPage.css';
import { GiConfirmed } from "react-icons/gi";

// Constant for the nutritional control meeting type
const CONTROL_NUTRICIONAL = "CONTROL NUTRICIONAL";
const ALLOWED_REFERRER = "https://app.simplymeet.me/";

const ConfirmationPage = () => {
  const [params, setParams] = useState({});
  const [showMessage, setShowMessage] = useState(true);
  const [isValidReferrer, setIsValidReferrer] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Extract and decode URL parameters when the location changes
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    // Use for to iterate over the entries of searchParams
    // This is more concise than using forEach and works well with iterables
    for (let [key, value] of searchParams.entries()) {
      // Decode each value and store it in the paramsObject
      if (key === "invitee_email") paramsObject[key] = decodeURIComponent(value);
      else paramsObject[key] = decodeURIComponent(value.toUpperCase());
    }
    setParams(paramsObject);
  }, [location]);

  const closeMessage = () => setShowMessage(false);

  // Function to translate and format the date string to Spanish
  const translateDate = (dateString) => {
    const date = new Date(dateString);
    // Options for date formatting
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    return date.toLocaleDateString('es-ES', options);
  };

  // If the referrer is not valid, redirect to the home page
  useEffect(() => {
    // Check if the referrer is from the allowed domain
    const referrer = document.referrer;
    const isValid = referrer.startsWith(ALLOWED_REFERRER);
    setIsValidReferrer(isValid);
  }, [location]);

  if (isValidReferrer === null) {
    // Still checking the referrer
    return null;
  }

  if (!isValidReferrer) {
    // Referrer is invalid, redirect to home
    return <Navigate to="/" />;
  }

  return (
    <div className="confirmation-page">
      <div className="confirmation-content">
        {showMessage && (
          <div className="top-message">
            <button className="close-button" onClick={closeMessage}>×</button>
            {
              params.meeting_type === CONTROL_NUTRICIONAL ?
                <p className="message-text">
                  <strong>¡Gracias!</strong><br />
                  Te sugiero <strong><u>programar un nuevo control</u></strong> después de esta consulta para asegurar los días y horarios de tu preferencia. <br />
                  Por favor, recuerda llegar con 5 minutos de anticipación.
                </p>
                :
                <p className="message-text">
                  <strong>¡Gracias!</strong><br />
                  Te sugiero <strong><u>programar una segunda consulta</u></strong> para la semana siguiente, donde te entregaré tu plan personalizado, en caso de que lo necesites. <br />
                  Además, recomiendo <strong><u>agendar un control un mes después de tu primera consulta</u></strong> para asegurar tu horario de preferencia.
                </p>
            }
            <button className="ok-button" onClick={closeMessage}>OK</button>
          </div>
        )}
        <div className="confirmation-details">
          <div className="confirmation-icon"><GiConfirmed /></div>
          <h3 style={{
            fontSize: 'clamp(1.2rem, 3vw, 1.8rem)',
            textAlign: 'center',
            wordBreak: 'break-word'
          }}>
            Confirmación de <strong>"{params.meeting_type}"</strong>
          </h3>
          <br />
          <p style={{
            fontSize: 'clamp(1.1rem, 2.5vw, 1.5rem)',
            wordBreak: 'break-word'
          }}>
            Estimado/a <strong>{params.invitee_full_name}</strong>,
          </p>
          <p style={{
            fontSize: 'clamp(1rem, 2vw, 1.3rem)',
            wordBreak: 'break-word'
          }}>
            Su evento <strong>"{params.meeting_type}"</strong> ha sido confirmado para el día <strong>{translateDate(params.meeting_starts_at)}hs</strong>.
          </p>
          <p style={{
            fontSize: 'clamp(1rem, 2vw, 1.3rem)',
            wordBreak: 'break-word'
          }}>
            Se enviará un correo de confirmación a: <strong>{params.invitee_email}</strong>
          </p>
        </div>
          <div className="calendar-embed">
            <iframe
              src="https://app.simplymeet.me/bianangelucci/controlnutricional"
              width="100%"
              height="1300px"
              frameBorder="0"
            ></iframe>
          </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;