import React, { useEffect, useState } from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import { InlineWidget, PopupWidget } from "react-calendly";
const { REACT_APP_EMAILJS_ID, REACT_APP_EMAILJS_TEMPLATE_ID, REACT_APP_EMAILJS_PUBLIC_KEY } = process.env;

const Contact = () => {
  const [info, setInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();
    if (!info.name) {
      swal("¡Debe completar todos los campos!", { icon: "warning" });
    }
    if (!info.email) {
      swal("¡Debe completar todos los campos!", { icon: "warning" });
    }
    if (!info.subject) {
      swal("¡Debe completar todos los campos!", { icon: "warning" });
    }
    if (!info.message) {
      swal("¡Debe completar todos los campos!", { icon: "warning" });
    } else {
      emailjs
        .sendForm(
          REACT_APP_EMAILJS_ID,
          REACT_APP_EMAILJS_TEMPLATE_ID,
          e.target,
          REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (res) => {
            swal("¡Gracias por contactarme! Responderé a la brevedad", {
              icon: "success",
            });
            e.target.reset();
          },
          (error) => {
            swal("¡Ha ocurrido un error! Intenta nuevamente más tarde", {
              icon: "error",
            });
            console.log(error.text);
          }
        );
    }
  };
  console.log(document.getElementsByClassName("back-button"))

  return (
    <>
      <div className="contactContainer">
        <div className="formContainer" id="contactForm">
          <h2 className="titleContact">
            Puedes enviarme un mail con tus dudas aquí
          </h2>
          <form className="contactForm" id="form" onSubmit={sendEmail} autoComplete="off">
            <label htmlFor="name" className="titleLabel">
              Nombre Completo
            </label>
            <input
              type="text"
              name="name"
              className="inputStyle"
              onChange={handleChange}
              autoComplete="off"
            />
            <label htmlFor="email" className="titleLabel">
              Email
            </label>
            <input
              type="email"
              name="email"
              className="inputStyle"
              onChange={handleChange}
              autoComplete="off"
            />
            <label htmlFor="subject" className="titleLabel">
              Asunto
            </label>
            <input
              type="text"
              name="subject"
              className="inputStyle"
              onChange={handleChange}
              autoComplete="off"
            />
            <label htmlFor="message" className="titleLabel">
              Mensaje
            </label>
            <textarea
              type="text"
              name="message"
              rows="6"
              className="txtAreaStyle"
              onChange={handleChange}
            />
            <button type="submit" className="btnSend">
              Enviar
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
