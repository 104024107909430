import React from 'react';
import './CalendarPage.css';  // Asumiendo que tienes un archivo CSS separado para estilos

const CalendarPage = () => {
  return (
    <div className="calendar-container">
      <iframe
        src="https://app.simplymeet.me/bianangelucci?view=compact"
        title="SimplyMeet Calendar"
        className="responsive-iframe"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default CalendarPage;
