import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
const Faqs = () => {
  return (
    <div className='faqs' id='faqs'>
        <h1 className="titleServices">Preguntas Frecuentes</h1>
        <div className="accordion" id="accordionFlushExample" style={{boxShadow: "0 0.2rem 1.5rem rgba(0, 0, 0, 0.4)"}}>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <b>¿Qué pasa si deseo reprogramar mi turno?</b> 
                </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body text-start">Podes hacerlo desde el mail de confirmación del turno que habías seleccionado. Allí aparece una opción para reprogramar. La misma debe realizarse al menos 24 hs previas al turno.</div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    <b>¿Qué pasa si deseo cancelar mi turno?</b>
                </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body text-start">Podes realizarlo desde el mail de confirmación del turno que habías seleccionado. Allí aparece una opción para cancelarlo. Se realizará sin costo siempre y cuando la cancelación se realice 24 hs previas, de lo contrario se debe abonar el costo de seña.</div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    <b>¿Las consultas son únicamente particulares?</b>
                </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body text-start">Si, no trabajo a través de obras sociales.</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Faqs;