import React from "react";
import bian from "../../Assets/foto-nutri.webp";
import logoBian from "../../Assets/logo-nutri.png";
import heart from "../../Assets/iconos-04.png";
import yoga from "../../Assets/iconos-05.png";
import clock from "../../Assets/iconos-06.png";
import inbody from "../../Assets/Logos_nutri_INBODY.png";
import planes_personalizados from "../../Assets/Logos_nutri_planes_pers.png";
import abordaje_integral from "../../Assets/Logos_nutri_nutricion_integral_funcional.png";
import { MdLocationPin } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { ImWhatsapp } from "react-icons/im";

import "./About.css";
import BookButton from "../BookButton/BookButton";
const About = () => {
  return (
    <>
      <div className="abtUsContainer" id="about">
        <h1 className="titleInfo1">
          Lic. Bianca Angelucci
          <img src={logoBian} alt="logo" className="logoBian" loading="lazy" />
        </h1>
        <div className="us">
          <div className="txtAbtUs">
            <h3>¡Hola a todos!</h3>
            Soy <b>Bianca</b>, Licenciada en Nutrición, les doy la bienvenida a mi página web. <br /> Aquí podrán <b>agendar consultas</b> tanto <b>presenciales</b> como <b>online</b>.
            Me <b>especializo</b> en <b>nutrición funcional e integral</b>, así como en <b>salud digestiva</b>, lo que me permite ofrecer un enfoque completamente personalizado para cada uno de ustedes.
            <br />
            Mi objetivo es guiarlos hacia una salud óptima a través de la alimentación y apoyarlos en <b>cambios duraderos</b> en sus hábitos.
            Estoy entusiasmada por acompañarlos en su búsqueda de una vida más saludable. Para consultas, <b>no duden en contactarme</b>. Bianca Angelucci (M.P. - 1847).{" "}
            <br />
            <br />
          </div>
          <div className="employeeContainer">
            <div className="employee">
              <img src={bian} alt="employee" className="employeePhoto" loading="lazy" />
            </div>
          </div>
        </div>
        <div className="separatorDiv1">
          <img src={heart} alt="heart icon" className="iconSeparators" loading="lazy" />
        </div>
        <div className="servicesContainer">
          <div className="services" id="services">
            <h1 className="titleServices">Servicios</h1>
            <div className="grid1">
              <div className="cardService">
                <div>
                  <h2 className="titleService">Abordaje</h2>
                  <h2 className="subtitleService">integral-funcional</h2>
                </div>
                <p className="serviceList">
                  Este tipo de abordaje no solo se limita al tratamiento del síntoma, sino que evalúa de forma completa los hábitos del paciente, dando así la posibilidad de generar estrategias y herramientas personalizadas y adaptadas a cada uno. La ventaja fundamental radica en generar cambios duraderos, prevenir enfermedades y el tratamiento interdisciplinario, lo cual dista significativamente una atención convencional.
                </p>
                <img
                  src={abordaje_integral}
                  alt="logo"
                  className="abordaje_integral"
                  loading="lazy"
                />
              </div>
              <div className="cardService">
                <h2 className="titleService">InBody</h2>
                <p className="serviceList">
                  Es una balanza de bioimpedancia que podes realizar de forma complementaria a la consulta nutricion (está incluida en el costo de la misma), u optar por realizar solo dicho estudio. Hoy en día, es la forma más actualizada y precisa para conocer composición corporal (músculo, masa ósea, grasa, agua) entre muchos parámetros más.
                </p>
                <img
                  src={inbody}
                  alt="logo"
                  className="educacion_alimentaria"
                  loading="lazy"
                />
              </div>
              <div className="cardService">
                <h2 className="titleService">Planes</h2>
                <h2 className="subtitleService">personalizados</h2>
                <p className="serviceList">
                  Cada plan es realizado de forma personalizada, lo cual considera no solo tus objetivos, sino que también: gustos, hábitos, posibilidades, horarios, actividades, etc. Además vas a aprender estrategias alimentarias que te ayudaran a tomar mejores decisiones y tener una vida más saludable en todos sus aspectos.
                </p>
                <img
                  src={planes_personalizados}
                  alt="logo"
                  className="planes_personalizados"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <BookButton />
        </div>
        <div className="separatorDiv">
          <img src={yoga} alt="yoga icon" className="iconSeparators" loading="lazy" />
        </div>
        <div className="location" id="contact">
          <div className="contactInfo">
            <h1 className="titleInfo">Contacto</h1>
            <span className="info">
              <BiWorld className="iconInfo" /> Web:{" "}
              <a href="http://www.bianangelucci.com/" className="linkInfo">
                www.bianangelucci.com
              </a>
            </span>
            <span className="info">
              <MdLocationPin className="iconInfo1" /> Dirección:{" "}
              <a
                href="https://maps.app.goo.gl/9QTAJiNwLiUvrrs28"
                target="_blank"
                rel="noreferrer"
                className="linkInfo"
              >
                Junin 2612, S3000 Santa Fe de la Vera Cruz, Santa Fe
              </a>
            </span>
            <span className="info">
              <HiOutlineMail className="iconInfo" />
              Email:{" "}
              <a
                href="mailto:lic.angeluccibianca@gmail.com"
                className="linkInfo"
              >
                lic.angeluccibianca@gmail.com
              </a>
            </span>
            <span className="info">
              <ImWhatsapp className="iconInfo" /> WhatsApp:{" "}
              <a
                href="https://api.whatsapp.com/send?phone=5493425827268&text=Hola,%20como%20estas?%20Me%20gustar%C3%ADa%20realizar%20una%20consulta%20sobre%20sus%20servicios.."
                target="_blank"
                rel="noreferrer"
                className="linkInfo"
              >
                +54 9 342 582 7268
              </a>
            </span>
            <h1 className="titleInfo">Horarios</h1>
            <span className="info">
              <b className="horarios">Lunes </b> - 08:00 a 19:00hs
            </span>
            <span className="info">
              <b className="horarios">Martes </b> - 08:00 a 19:00hs
            </span>
            <span className="info">
              <b className="horarios">Miercoles </b> - 13:00 a 19:00hs
            </span>
            <span className="info">
              <b className="horarios">Jueves </b> - 08:00 a 19:00hs
            </span>
            <span className="info">
              <b className="horarios">Viernes </b> - 08:00 a 19:00hs
            </span>
            <span className="info">
              <b className="horarios">Sábados y Domingos</b> - Cerrado
            </span>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3396.8220111813866!2d-60.706932723732656!3d-31.638723374159945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b5a9b93ee9600b%3A0x8ede991f69874968!2sJun%C3%ADn%202612%2C%20S3000%20Santa%20Fe%20de%20la%20Vera%20Cruz%2C%20Santa%20Fe!5e0!3m2!1ses-419!2sar!4v1722846889959!5m2!1ses-419!2sar"
            width="600"
            height="500"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="location"
            className="map"
          ></iframe>
        </div>
        <div className="separatorDiv1">
          <img src={clock} alt="clock icon" className="iconSeparators" loading="lazy" />
        </div>
      </div>
    </>
  );
};

export default About;
