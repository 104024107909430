import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { GrInstagram } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa/";

export const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const closeNav = () => {
    setToggleMenu(false);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Si el menú está abierto y el clic no es dentro del navbar, cierra el menú
      if (toggleMenu && !event.target.closest('.sidebar')) {
        setToggleMenu(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [toggleMenu]);

  return (
    <>
      <nav className="sidebar">
        {(toggleMenu || screenWidth > 768) && (
          <>
            <ul className="list">
              <div className="leftSideNav">
                <a href="/" className="navlinks" onClick={closeNav}>
                  <li className="items">Inicio</li>
                </a>
                <a href="/#about" className="navlinks" onClick={closeNav}>
                  <li className="items">Sobre mí</li>
                </a>
                <a href="/#services" className="navlinks" onClick={closeNav}>
                  <li className="items">Servicios</li>
                </a>
                <a href="/#contact" className="navlinks" onClick={closeNav}>
                  <li className="items">Contacto</li>
                </a>
                <a href="/#faqs" className="navlinks" onClick={closeNav}>
                  <li className="items">Preguntas Frecuentes</li>
                </a>
                <a href="/calendar" className="navlinks" onClick={closeNav}>
                  <li className="items schedule_consultation">Agendar consulta!</li>
                </a>
                <a href="https://nutfrutossecos.empretienda.com.ar/" className="navlinks" target="_blank" onClick={closeNav}>
                  <li className="items shop">Tienda Natural</li>
                </a>
              </div>
              <div className="socialMedias">
                <a
                  href="https://www.instagram.com/nutri.biancaangelucci/"
                  target="_blank"
                  rel="noreferrer"
                  className="mediaLinks"
                >
                  <button className="iconoIg">
                    <GrInstagram />
                  </button>
                </a>
              </div>
            </ul>
          </>
        )}
        <button onClick={toggleNav} className="btnResponsive">
          &#9776;
        </button>
      </nav>
    </>
  );
};

export default Navbar;
