import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./CarouselImages.css";
import apple from "../../Assets/iconos-03.png";

// Import images
import banner from "../../Assets/banner.webp";
import desk1 from "../../Assets/office-2.webp";
import desk2 from "../../Assets/desk2.webp";
import desk4 from "../../Assets/desk4.webp";
import desk5 from "../../Assets/desk5.webp";
import desk6 from "../../Assets/office-3.webp";

const images = [
  { src: banner, alt: "First slide", caption: "" },
  { src: desk6, alt: "Second slide", caption: "ASESORAMIENTO PERSONALIZADO" },
  { src: desk1, alt: "Third slide", caption: "PLANES ADECUADOS A TUS OBJETIVOS" },
  { src: desk2, alt: "Fourth slide", caption: "Bianca Angelucci", subcaption: "Nutricionista" },
  { src: desk5, alt: "Fifth slide", caption: "Bianca Angelucci", subcaption: "Nutricionista" },
  { src: desk4, alt: "Sixth slide", caption: "Bianca Angelucci", subcaption: "Nutricionista" },
];

const CarouselImages = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="carousel">
      <Carousel fade pause="hover" touch controls activeIndex={index} onSelect={handleSelect}>
        {images.map((image, idx) => (
          <Carousel.Item key={idx} interval={3000}>
            <img
              className={`d-block w-100 ${idx === 0 ? 'imagen-test' : ''}`}
              src={image.src}
              alt={image.alt}
              loading={idx === 0 ? "eager" : "lazy"}
            />
            <Carousel.Caption>
              {image.caption && (
                <h3 className="txt1 showUpFx">
                  {image.caption}
                  {image.subcaption && <b className="apellido"> {image.caption.split(' ')[1]}</b>}
                </h3>
              )}
              {image.subcaption && <p className="txt2 showUpFx">{image.subcaption}</p>}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="separatorDiv">
        <img src={apple} alt="apple icon" className="iconSeparators" loading="lazy" />
      </div>
    </div>
  );
};

export default CarouselImages;