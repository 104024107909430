import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import CarouselImages from "./Components/CarouselImages/CarouselImages";
import Contact from "./Components/Contact/Contact";
import About from "./Components/About/About";
import { Fade } from "react-awesome-reveal";
import Footer from "./Components/Footer/Footer";
import Faqs from "./Components/Faqs/Faqs";
import CalendarPage from "./Components/CalendarPage/CalendarPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToHashElement from './ScrollToHashElement';
import FloatingButton from './Components/FloatingButton/FloatingButton';
import ConfirmationPage from './Components/ConfirmationPage/ConfirmationPage';

function App() {
  return (
    <Router hashType="hashbang">
      <ScrollToHashElement />
      <div className="App" id="app">
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Fade top cascade>
                <CarouselImages />
              </Fade>
              <Fade top cascade>
                <About />
              </Fade>
              <Fade top cascade>
                <Faqs />
              </Fade>
              <Contact />
              <Fade top cascade>
                <Footer />
              </Fade>
            </>
          } />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
        </Routes>
        <FloatingButton />
      </div>
    </Router>
  );
}

export default App;