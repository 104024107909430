import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BookButton.css'; // Puedes agregar estilos personalizados aquí

const BookButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/calendar');
    };

    return (
        <div className="cta-container">
            <h1 className="cta-title">¡Transforma tu salud hoy!</h1>
            <p className="cta-description">Reserva tu consulta de manera rápida y sencilla para empezar tu cambio hacia una vida más saludable.</p>
            <button onClick={handleClick} className="cta-button">
                <span className="cta-button-icon">📅</span> Agendar Consulta
            </button>
        </div>
    );
}

export default BookButton;
