import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './FloatingButton.css';

const FloatingButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate('/calendar');
  };

  if (location.pathname === '/calendar' || location.pathname === '/confirmation') {
    return null;
  }

  return (
    <button className="floating-button" onClick={handleClick}>
      Agendar consulta!
    </button>
  );
};

export default FloatingButton;